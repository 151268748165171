<template>
  <div class="page">
    <v-carousel :slideList="slideList" theme="pink" />
    <ul class="nav">
      <li
        class="item"
        :class="{ active: menu.id === activeMenu.id }"
        v-for="(menu, i) in menuList"
        :key="i"
        @click="menuClickHandler(menu)"
      >
        {{ menu.name }}
      </li>
    </ul>
    <div class="container">
      <section class="figure">
        <vc-calendar v-if="activeMenu.type === 2" :attributes="attrs" />
        <img v-else :src="activeMenu._icon" class="img" alt="" />
      </section>
      <!-- <ul class="list" v-if="articleList.length">
        <li
          class="item"
          :class="item.color"
          v-for="(item, i) in articleList"
          :key="i"
          @click="articleClickHandler(item)"
        >
          <span class="wrapper">
            <span class="date">{{ item.time }}</span
            >{{ item.name }}
          </span>
        </li>
      </ul>
      <div v-else class="placeholder">版块升级中，敬请期待</div> -->

      <div style="margin-top: 40px; margin-left: 40px; font-size: 22px;">
        <a @click="downLoad">1.通知--第三届校园气象观测和应用挑战赛</a>
      </div>
    </div>
  </div>
</template>
<script>
import Carousel from "@/components/carousel";
import {$garden} from '@/services'
import {ROOT} from '@/services/base'
import axios from 'axios';
export default {
  name: "Science",
  components: {
    "v-carousel": Carousel,
  },
  data() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return {
      slideList: [
        {
          source: require("../assets/banner1.png"),
        },
        {
          source: require("../assets/banner1.png"),
        },
      ],
      menuList: [],
      activeMenu: {},
      articleList: [],
      attrs: [
        {
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "solid",
            contentClass: "italic",
          },
          dates: new Date(year,month, 6),
        },
      ],
    };
  },
  watch: {
    activeMenu(nV) {
      this.getArticleList(nV)
    }
  },
  created() {
    $garden.getMenuList({parentId: this.$route.query.id}).then(res => {
      this.menuList = res.data.map(menu => ({
        ...menu,
        _icon: `${ROOT}/${menu.icon}`
      }))
      this.activeMenu = this.menuList[0]
    })
  },
  methods: {
    menuClickHandler(menu) {
      this.activeMenu = menu
      this.getArticleList(menu)
    },
    getArticleList(menu, filter = {}) {
      let obj = {
        parentId: menu.id
      }
      if (menu.type === 2) {
        obj.date = filter.date
      }
      $garden.getFileList(obj).then(res => {
        this.articleList = res.data.map(article => ({
          ...article,
          fileUrl: `${ROOT}/${article.url}`
        }))
      })
    },
    articleClickHandler(article) {
      this.$router.push({name: 'reader', query: {url: article.fileUrl}})
    },
    async downLoad() {
      const response = await axios({
        url: 'https://kxwj.cn/observer/files/第三届校园气象观测和应用挑战赛.pdf',
        method: 'GET',
        responseType: 'blob', // 必须指定为blob类型才能下载
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '第三届校园气象观测和应用挑战赛.pdf');
      document.body.appendChild(link);
      link.click()
    }
  },
};
</script>
<style lang="less" scoped>
@import url("@/styles/news");
@pink: #647ccb;
@blue: #0517c0;
/deep/ .vc-day-content {
  width: 41px;
  height: 41px;
  color: #999;
  margin: 10px 0;
}
/deep/ .vc-container {
  width: 430px;
  height: 430px;
  margin-bottom: 73px;
  box-shadow: 0px 6px 30px 0px @pink;
  border-radius: 90px;
  position: relative;
  background: #fff;
  z-index: 3;
  &::before {
    content: "";
    position: absolute;
    background: #fff;
    z-index: 0;
    left: 13px;
    top: 10px;
    width: 405px;
    height: 433px;
    background: #ffffff;
    box-shadow: 0px 6px 30px 0px @pink;
    opacity: 0.1;
    border-radius: 90px;
  }
  &::after {
    content: "";
    position: absolute;
    background: #fff;
    z-index: -1;
    left: 25px;
    top: 20px;
    width: 380px;
    height: 433px;
    background: #ffffff;
    box-shadow: 0px 6px 30px 0px @pink;
    opacity: 0.1;
    border-radius: 90px;
  }
}
/deep/ .vc-arrows-container {
  justify-content: space-around;
}
/deep/ .vc-arrow {
  color: @pink;
}
/deep/ .vc-title {
  color: @pink;
}
.page {
  width: 1190px;
  margin: 0 auto;
  .nav {
    margin: 10px 0 84px;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: @blue;
    background: @pink;
    display: flex;
    align-items: center;
    .item {
      height: 100%;
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:not(:first-child)::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 10px;
        width: 1px;
        background: @blue;
      }
      &.active {
        color: #fff;
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: calc(50% - 13px);
          width: 0;
          height: 0;
          border: 13px solid transparent;
          border-top: 13px solid @pink;
        }
      }
    }
  }
}
.container {
  background: #fff;
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  margin-top: -83px;
  padding: 51px 39px 33px 63px;
  .figure {
    width: 500px;
    text-align: center;
    .img {
      width: 320px;
    }
  }
  .list {
    .item {
      cursor: pointer;
      color: #595959;
    }
  }
  .placeholder {
    width: 100%;
    padding: 50px 20px 90px;
    font-size: 30px;
    color: green;
    text-align: center;
  }
}
</style>