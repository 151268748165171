<template>
  <swiper class="swiper" :class="theme" :options="swiperOptions">
    <swiper-slide v-for="(slide, i) in slideList" :key="i"
      ><img :src="slide.source"
    /></swiper-slide>
    <div class="swiper-button-prev" :class="theme" slot="button-prev"></div>
    <div class="swiper-button-next" :class="theme" slot="button-next"></div>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Carousel",
  components: {
    swiper: Swiper,
    "swiper-slide": SwiperSlide,
  },
  props: {
    slideList: {
      require: true,
      default: () => [],
    },
    theme: String,
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
<style lang="less">
@import url("@/styles/pagination");
.swiper {
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    background-color: #fff;
    img {
      width: 100%;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 24px;
    height: 34px;
    opacity: 0.6;
    &:after {
      font-size: 12px;
      color: #fff;
    }
  }
  .swiper-button-prev {
    border-radius: 0px 17px 17px 0px;
    left: 0;
    &.pink {
      background: @pink;
    }
  }
  .swiper-button-next {
    border-radius: 17px 0px 0px 17px;
    right: 0;
  }
}
</style>